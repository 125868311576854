@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import './constants/styles.css';

@font-face {
  font-family: 'AcidGrotesk';
  src: local('AcidGrotesk-Regular'),
    url(./assets/fonts/AcidGrotesk-Regular.otf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'AcidGrotesk-Medium';
  src: local('AcidGrotesk-Medium'),
    url(./assets/fonts/AcidGrotesk-Medium.otf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'AcidGrotesk-Bold';
  src: local('AcidGrotesk-Bold'),
    url(./assets/fonts/AcidGrotesk-Bold.otf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
}

h1 {
  font-family: 'AcidGrotesk-Bold', system-ui;
}

html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Mulish', sans-serif;
}
#root {
  height: 100%;
  width: 100%;
}

.MuiModal-root {
  z-index: 100 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}